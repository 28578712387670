<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="500px"
      @closed="closeModal" @open="openModal">
      <div>
        <ch-form :render-option="option" :model="modalForm" :rules=rules
                 :props="{ labelWidth: '90px' , paddingRight: '0'}"
                  ref="modalForm"
        >
          <template #code>
            <el-input
              size="small"
              v-model="modalForm.code"
              placeholder="请输入验证码"
              maxlength="4"
              style="width: 120px"
            />
            <ch-auth-code-down @click="sendAuthCode">获取验证码</ch-auth-code-down>
          </template>

          <template #qrcode>
            <el-image
              style="width: 100px; height: 100px"
              :src="url"
              fit="fill"></el-image>
          </template>

        </ch-form>
        <span slot="footer" class="flex_con" v-if="type === 'code'">
          <ch-button @click="closeModal" type="cancel">关闭</ch-button>
        </span>

        <span slot="footer" class="flex_con" v-else>
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
            @click="handleModal('modalForm')"
            :loading="isLoading"
            type="ok" >确定</ch-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import qs from "qs";
import code from '@/assets/img/code.png'
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.modalForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      title: '',
      modalForm: {},
      isOpenModal: false,
      isLoading: false,
      type: '',
      url: code,
      rules: {
        surePassword: [
          {required: true, message: "请确认密码", trigger: ["blur", "change"],},
          {validator: validatePass, message: "两次输入密码不同", trigger: ["blur", "change"],},
        ],
        newPassword: [{required: true, message: "请输入新密码", trigger: ["blur", "change"],},],
      }
    }
  },
  computed: {

    option: function () {
      if (this.type === 'watch' && this.isOpenModal) {
        return []
      }else if( this.type === 'code') {
        return [
          {type: 'slot', label: '',slotName: 'qrcode', labelWidth: '0px' },
        ]
      } else {
        return [
          {type: 'input', label: '手机号码：', prop: 'phone', placeholder: "请输入手机号码", props: {readonly: true}},
          {type: 'slot', label: '验证码：', prop: 'postSort', slotName: 'code'},
          {type: 'input', label: '原密码：', prop: 'oldPassword', placeholder: "请输入原密码"},
          {type: 'input', label: '新密码：', prop: 'newPassword', placeholder: "请输入新密码"},
          {type: 'input', label: '确认密码：', prop: 'surePassword', placeholder: "请确认密码"},
        ]
      }
    },
    ...mapGetters(['userInfo'])
  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    // 打开修改密码
    openChangePassword() {
      this.title = '修改密码'
      this.type = 'pas'
      this.modalForm = {
        ...this.userInfo,
        phone: this.userInfo.phonenumber.substr(0,3)+"****"+ this.userInfo.phonenumber.substr(7)
      }

      this.isOpenModal = true
    },
    // 打开微信授权
    openWechatAuth() {
      this.title = '微信授权'
      this.type = 'code'
      this.isOpenModal = true
    },
    // 打开生成二维码
    openQrCode() {
      this.title = '二维码'
      this.type = 'code'
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      if(this.type === 'pas') {
        this.$refs[formRef].validate((valid) => {
          if (valid) {
            let data = {
              oldPassword: this.modalForm.oldPassword,
              newPassword: this.modalForm.newPassword,
            }
            this.isLoading = true
            this.$curl.put("/client/cliUser/profile/updatePwd?" + qs.stringify(data))
              .then(() => {
                this.$message.success("修改成功！");
                this.closeModal()
                this.getListFunction?.()
                this.isLoading = false
              }).catch(() => this.isLoading = false)
          }
        })
      }
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.editId = ''
      this.isOpenModal = false
      this.isLoading = false
    },

    // 请求验证码
    sendAuthCode() {
      this.$curl.post("/client/cliUser/profile/sendCode")
        .then(() => this.$message.success("发送验证码成功！"))
    }
  },
}
</script>

<style lang="scss" scoped>
</style>