<template>
  <div>
    <!--  基本资料  -->
    <ch-layout page class="basicInformation_wrapper">
      <template #default>
        <div class="main-wrapper">
          <div class="main">
            <p class="title">{{ userInfo.account + userInfo.name}}</p>
            <main>
              <ch-form
                  :render-option="option" :model="userInfo"
                  ref="modalForm" :splice="[6]" class="text_form_wrapper"
                  :props="{ labelWidth: '80px', paddingRight: '0px', paddingLeft: '0px'}"
              >
<!--                <template #accountName>-->
<!--                  <div class="accountName">-->
<!--                    <span>{{ communityInfo.accountName }}</span>-->
<!--                    &lt;!&ndash; <ch-button type="link">提现</ch-button>&ndash;&gt;-->
<!--                  </div>-->
<!--                </template>-->

                <template #balance>
                  <div class="balance">
                    <span>￥</span>
                    <span class="yuan">{{ userInfo.commissionBalance || '0.00' }}</span>
                    <span>元</span>
                    <!-- <ch-button type="link">提现</ch-button>-->
                  </div>
                </template>

                <template #gift>
                  <div class="balance">
                    <span>￥</span>
                    <span class="yuan">{{ userInfo.giftBalance || '0.00' }}</span>
                    <span>元</span>
                  </div>
                </template>
              </ch-form>
            </main>
          </div>
        </div>
      </template>

      <template #page>
        <div class="button">
          <ch-button @click="openChangePassword">修改密码</ch-button>
        </div>
      </template>
    </ch-layout>

    <!-- 查询 编辑 修改 -->
    <modal ref="modal"></modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "./modal";

export default {
  components: {Modal},
  data() {
    return {

    }
  },
  computed: {
    option: function () {
      return [
        {type: 'text', label: '公司名称：', prop: 'companyName'},
        {type: 'text', label: '部门名称：', prop: 'deptName'},
        {type: 'text', label: '岗位角色：', prop: 'roleGroup'},
        {type: 'slot', label: '余额：', slotName: 'balance'},
        // {type: 'slot', label: '赠送余额：', slotName: 'gift'},
        {type: 'text', label: '手机号码：', prop: 'iphone'},
        {type: 'text', label: '电子邮箱：', prop: 'email'},
        {type: 'text', label: '身份证号：', prop: 'idCard'},
        {type: 'text', label: '联系地址：', prop: 'address'},
        {type: 'text', label: '入职日期：', prop: 'induction'},
        {type: 'text', label: '备注信息：', prop: 'remark'},
      ]
    },
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    ...mapActions({
      getUserInfo: 'getUserInfo'
    }),
    // 打开修改密码
    openChangePassword() {
      this.$refs.modal?.openChangePassword()
    },
  }
}
</script>

<style scoped lang="scss">
.basicInformation_wrapper {

  .main-wrapper {
    height: 100%;
    overflow: auto;
    > .main {
      margin: 2% 5%;
      > main {
        // 表单切片的样式
        ::v-deep .form_splice_wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        // 表单 独立一列 样式
        ::v-deep .form_column_wrapper {
          width: 100%;
        }
        // 让查看变浅色
        ::v-deep .text_form_wrapper {
          .el-form-item__label {
            color: #9C9EA7;
          }
        }
        .balance {
          color: red;
          .yuan {
            font-size: 28px;
          }
        }
      }
      .title {
        margin: 20px 0;
        font-size: 24px;
      }
    }

  }

  .button {
    padding-bottom: 2%;
    > button {
      margin-left: 8px;
    }
  }
}
</style>